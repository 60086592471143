<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <p class="mb-0 text-h5 text-center">
          Dashboard Administrativo
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "HomePrivate",
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
